import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InterceptorService } from './services/interceptor.service';
import { DataSharingService } from './services/data.share.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AsyncPipe, CommonModule} from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InternalComponent } from './internal/internal.component';
import { CustPipe } from './pipe/cust.pipe';
import { Cust1Pipe } from './pipe/cust1.pipe';
import { HeaderComponent } from './common/header/header.component';
import { LoaderComponent } from './common/loader/loader.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './external/forgot-password/forgot-password.component';
import { LinkexpireComponent } from './external/linkexpire/linkexpire.component';
import { LoginComponent } from './external/login/login.component';
import { ResetPasswordComponent } from './external/reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

@NgModule({
  declarations: [
    AppComponent,
    InternalComponent,
    CustPipe,
    Cust1Pipe,
    HeaderComponent,
    LoaderComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    LinkexpireComponent,
    LoginComponent,
    ResetPasswordComponent,
 
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
         echarts 
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [ AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
    NgxSpinnerModule,
    DataSharingService],
    
  bootstrap: [AppComponent],
})
export class AppModule { }
