import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cust1'
})
export class Cust1Pipe implements PipeTransform {

  transform(word:string):string
  {
    if(!word) return word;
    let result = this.checkdata(word);
    // console.log(result);
    if(!result)
    {
      return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }
    else
    {
      var data:string='';
      let a:number=0;
      for(let x of result)
      {
        // console.log(a,x);
        // console.log(word[a].toUpperCase(),word.substr((a+1),x-a).toLowerCase());
        let abc = word[a].toUpperCase() + word.substr((a+1),x-a).toLowerCase();
        data = data.concat(abc);
        a=x+1;
      }
      let abc = word[a].toUpperCase() + word.substr((a+1)).toLowerCase();
      data = data.concat(abc);
      // console.log(data);
      return data;
    }
  }

  checkdata(word)
  {
    let data=[];
    for(let x=0;x<word.length;x++)
    {
      if(this.isUpperCase(word.charAt(x)))
      {
        // console.log("If");
        data.push(x);
      }
    }
    if(data.length>0)
    return data;
    return false;
  }

  isUpperCase(aCharacter)    
  {    
    return (aCharacter == ' ');
  }

}
