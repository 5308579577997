<ngx-spinner
  bdColor="rgba(255,255,255, 0.5)"
  size="medium"
  color="#1ab394"
  loadingText=""
  type="">

    <div id="preloader">
        <div class="loader"></div>
    </div>
</ngx-spinner>