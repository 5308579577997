import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class DataSharingService {

    constructor(){}

    public isLoginChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isProfileChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isPermissionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public headerProfile: BehaviorSubject<string> = new BehaviorSubject<string>(" ");
    public adminInfo;
    public adminInfoSubject : Subject<any> = new Subject<any>();
}

