import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal',
  templateUrl: './internal.component.html',
  styleUrls: ['./internal.component.css']
})
export class InternalComponent implements OnInit {
  navigationData: any;
  brandInfoData: any;
  modelInfoData: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  public setNavtionData(data) {
    this.navigationData = null;
    this.navigationData = data;
    localStorage.setItem('navigationData',JSON.stringify(data));

  }

  public getNavtionData() {
    if(this.navigationData){
      return this.navigationData;

    }
    else{
      return JSON.parse(localStorage.getItem('navigationData')) || null;
    }
    
    
  }



}
