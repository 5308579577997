import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private admin:AdminService) { }

  ngOnInit() {
  
    console.log(this.admin.loaderState.subscribe(data=>{}), "ajdksjdkasd==============")

    this.admin.loaderState.subscribe(data=>{
      this.spinner.show();
      if(data)
      this.spinner.show();
      else
      this.spinner.hide();
    });

    
  }

 

}
