<div class="wrapper login-section">
    <div class="container">
        <div class="login-container">
            <div class="row">
                <div class="col-12 ">
                    <div class="logo">
                        <img src="../../../assets/images/logo.svg" alt="logo">
                    </div>
                    <div class="whiteBox">

                        <div class="forgotWrap">
                            <div class="topBar">
                                <h1> Forgot your Password? </h1>
                                <p>Enter your email and we’ll send you a Verification code.</p>
                            </div>
                            <div class="form-box">
                                <div class="form">
                                    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                                        <div class="form-group">
                                            <input type="text" class="form-control" formControlName="email" [(ngModel)]="resetPasswordModel.email" autocomplete="off">
                                            <div class="error" *ngIf="resetPasswordForm.controls['email'].hasError('required') && showError">
                                                *Please enter your registered email address.
                                            </div>
                                            <div class="error"
                                                *ngIf="resetPasswordForm.controls['email'].hasError('email') && showError">
                                                *Please enter a valid email address.
                                            </div>
                                        </div>
                                        <div class="submitWrap">
                                            <button type="submit" class="btn">Reset Password</button>
                                        </div>
                                        <div class="backbtn">
                                            <a href="javascript:;" routerLink="/login">Go Back to Login</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>