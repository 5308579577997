<div class="wrapper login-section">
    <div class="container">
        <div class="login-container">
            <div class="row">
                <div class="col-12 ">
                    <div class="logo">
                        <img src="../../../assets/images/logo.svg" alt="logo">
                    </div>
                    <div class="whiteBox">
                        <div class="loginWrap">
                            <div class="topBar">
                                <h1>Login</h1>
                            </div>
                            <div class="form-box">
                                <form [formGroup]="form" (ngSubmit)="loginFun()">
                                    <div class="form">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" id="email" class="form-control" formControlName="email" autocomplete="off" [(ngModel)]="loginModel.email">
                                            <div class="error" *ngIf="form.controls['email'].hasError('required') && showError">
                                                *Please enter an email address.
                                            </div>
                                            <div class="error" *ngIf="form.controls['email'].hasError('email') && showError">
                                                *Please enter a valid email address.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control" formControlName="password" autocomplete="off" [(ngModel)]="loginModel.password">
                                            <div class="error" *ngIf="form.controls['password'].hasError('required') && showError">
                                                *Please enter password.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row align-items">
                                                <div class="col-7">
                                                    <label class="switch">
                                                        <input type="checkbox" [checked]="isRemember" (change)="changeRememberme($event)">
                                                        <span class="slider"></span>
                                                        <span class="txt">Remember Password</span>
                                                    </label>
                                                </div>
                                                <div class="col-5">
                                                    <a href="javascript:;" class="forgotPwd" routerLink="/forgot-password"> Forgot password?</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="submitWrap">
                                            <!-- <a href="dashboard.html" class="btn"> Login </a> -->
                                            <input type="submit" class="submit btn" value=" LOGIN " />
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
