export const apiUrl = {
    _adminLogin:'admin/login',
    _forgotPassword:'admin/forgot_password',
    _resetPassword:'admin/reset_password',

    _getAdvertismentList: 'admin/advertisement',
    _getAdvertismentDetails: 'admin/advertisement',

    _getplanData: 'admin/plan',
    _deleteAdvertisment: 'admin/advertisement',
    _createAdvertisment: 'admin/advertisement',
    _uploadAdvertisment: 'admin/advertisement/upload',
    _blockAdvertisment: 'admin/advertisementblockById',

    _getNotificationList: 'api/admin/broadcastList',
    _addNotification : 'api/admin/createBroadcast',
    _deleteNotification: 'api/admin/deleteBroadcastById',
    _getNotificationById: 'api/admin/detailBroadcastById',

    _addFaq : 'admin/faq',
    _uploadFaq : 'admin/faq/upload',
    _getAllFaq : 'admin/faq/getAllData',

    _getTechSupportContact: 'admin/contact',
    _updateContact: 'admin/contact',
    
    _getAllSupportList : 'admin/support/list',
    _getSupportDetails : 'admin/support',
    _statusNotification : 'admin/support/reply',

    _addAdmin : 'admin/admins',
    _uploadAdminImage : 'admin/upload',
    _getAdminList : 'admin/list',
    _getAdminDetails : 'admin/list',
    _blockAdmin : 'admin/block',
    _editAdmin : 'admin/profile',
    _resendPassword : 'admin/forgot_password',

    _updateAdminProfile : 'admin/profile',
    _changePassword : 'admin/change_password',
    
    _getcategoryData:'api/admin/adminCategoryList',
    _updateData:'admin/plan',
    _uploadCategory:'admin/upload',
    _addCategory:'api/category/createCategory',  
    _updateCategory:'api/category/updateCategory',
    _getsubcategoryData:'admin/category/subCategoryList',
    _addsubCategory: 'api/category/addSubCategory',
    _addAttribute:'api/category/addAttribute',
    _editAttribute:'api/category/editAttributeById',
    _addTraits:'api/category/addedTraits',
    _getAttributesList:'admin/category/adminAttributesList',
    _getTraitList:'admin/category/adminTraitList',
    _editTraitList:'api/category/editTraitById',
    _addsubTraits:'api/category/addedSubTraits',
    _getsubTraits:'api/category/subTraitList',
    _getPayment:'admin/payment/list',
    _getStates:'api/common/states',
    _getCategories:'api/admin/adminCategoryList',
    _updateAdmin:'admin/profile',
    _getuserCate:'api/advertisement/searchProduct',
    _getDealList: 'admin/deals',
    _getDealById: 'admin/dealDetailById',

    _getOneTimeFeeList : 'admin/user/onetimefee',
    _addOneTimeFee : 'admin/user/onetimefee',
    _uploadRates : 'admin/rate/import',
    _getRatesList: 'admin/rate/list',
    _sellerPrice: 'admin/user/sellingPrice',

    _getUserdata:'admin/user/list',
    _getUserRatesButtonClickData:'api/get_user_actions?name=RATES_BUTTON_CLICK',
    
    _getUserdetails:'admin/user/userDetailById',
    _blockUser:'admin/user/userBlockById',


    _getRedeemPointsList : 'admin/redeemPointList',
    _getRedeemPointsHistoryList : 'admin/redeemPointHistoryList',
    _updateReddemPointStatus : 'admin/redeemPointList',

    _verfiedUser:'admin/user/verified',
    
    _getDashboard:'admin/dashboard',
    _getSearch:'admin/topsearch',

    _getUserGraph: 'admin/dashboard/userGraph',
    _getPaymentGraph: 'admin/dashboard/paymentGraph',

    _getRatingsList: 'admin/ratings',
    _getRatingsById: 'admin/ratings',
    _updateRating: 'admin/ratings',

    _getCallLogList: 'admin/calllog/list',

    _deleteCategory: 'admin/category/delete',
    _deleteSubCategory: 'admin/category/subcategory/delete',
    _deleteAttribute: 'admin/attribute/delete',
    _deleteTraits: 'admin/trait/delete',
    _addUnit:'api/category/createUnit',
    _getUnitList:'api/category/unitList',
    _updateUnitList:'api/category/updateUnit',
    _deleteUnit:'api/category/deleteUnit',

    _deleteAttributeByid:'api/category/deleteAttributeById',
    _deleteTraitsByid:'api/category/deleteTraitById',

    _objectStore:'api/objectstore'
};
