import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { DataSharingService } from '../../services/data.share.service';
import swal from "sweetalert2";
import { apiUrl } from '../../global/global';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 
  firstName: any;
  profilePic: any;
  allData : any = [];
  accessToken: any;
  adminID : string;
  search : string;
  showError = false;
  showNofound = false;
  img : any;
  s3Url:any;

  constructor(private router: Router, 
    private adminService: AdminService, private dataSharingService: DataSharingService) {
    // this.dataSharingService.isProfileChanged.subscribe(value => {
          
    // });
    this.firstName = localStorage.getItem('firstName');
    this.profilePic = localStorage.getItem('profilePic') 
  
  }

  ngOnInit(): void {
    this.s3Url = localStorage.getItem('s3Url'); 
    this.adminID = localStorage.getItem('userId');
    this.adminDetails();
    this.dataSharingService.headerProfile.subscribe(res => {
      //console.log(res);
      this.allData.profilePic = res;
    })
  }

  adminDetails() {
    let params = new HttpParams();
    
    this.adminService.getDetail(apiUrl._getAdminDetails + '/' + this.adminID, {} ).subscribe(res => {
      this.allData = res.data;
      console.log(res);
      //console.log(this.allData, "========Profile-Details ");
      this.showNofound= true;
      this.dataSharingService.adminInfo = this.allData;
      this.dataSharingService.adminInfoSubject.next(this.allData);
    },
      err => {
        console.log(err);
      });
  }


  logout() {

    swal.fire({
      icon: "warning",
      text: 'Are you sure you want to logout ?',
      showCancelButton: true,
      confirmButtonColor: '#2FC863',
      cancelButtonColor: '#2C2C2C',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('permissionData');
        localStorage.removeItem('permissionId');
        localStorage.removeItem('s3Url');
        localStorage.removeItem('s3Folders');

        this.router.navigate(['/login']);
      }
    });
  }
  

}
