<div class="top-bar">
    <div class="left-section">
        <div class="logo" routerLink="/dashboard">
            <a href="javascript:;" routerLink="/dashboard"><img src="../../../assets/images/logo.svg"></a>

        </div>
        <div class="toggle-btn">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <!-- navbar -->
    <div class="side-bar">
        <div class="nav-outer scroll_custom">
            <ul class="nav-bar">
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/dashboard"> Dashboard </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/users"> Users </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/users-rate-actions"> User Rates Actions </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/payments"> Payments </a>
                </li>

                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/advertisement"> Advertisement </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/categories"> Categories  </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/pricing"> Pricing </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/redeem-points"> Redeem Points </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/deals"> Deals </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/subscription-plan"> Subscription Plan </a>
                </li>
                <!-- <li routerLinkActive="active">
                   
                </li> -->
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/ratings"> Ratings </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" routerLink="/call-log">Call Log  </a>
                </li>
                <li routerLinkActive="active">
                    <a href="javascript:;" [routerLink]="'/notification'"> Notifications </a>
                </li>

            </ul>
        </div>
    </div>
    <div class="right-section">
        <ul>
            <li>
                <div class="dropdown profile-settings" ngbDropdown placement="bottom-right">
                    <div class="profile-btn " href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" ngbDropdownToggle>
                        <img src="{{allData?.profilePic ? (s3Url+'admins/'+allData?.profilePic) : '/assets/images/thumbnail.png'}} ">
                    </div>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownMenuLink" class="dropdown-box">
                        <!-- <a href="javascript:;"  routerLink="/">  </a>  -->
                        <a class="dropdown-item" [routerLink]="'/technical-support'">Technical Support </a>
                        <!-- <a class="dropdown-item" [routerLink]="'/general-settings'"> General Settings </a> -->
                        <a class="dropdown-item" [routerLink]="'/admin-profile'"> Admin Profile </a>
                        <a class="dropdown-item" href="javascript:;" routerLink="/admins"> Admins  </a>

                        <a class="dropdown-item" [routerLink]="'/faq'"> FAQs </a>
                        <div class="dropdown-menu-footer">
                            <a href="javascript:;" (click)="logout()"> Logout </a>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>

<!-- navbar -->
<!-- <div class="side-bar">
    <div class="nav-outer scroll_custom" >
        <ul class="nav-bar">
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/dashboard"> Dashboard </a>
            </li>
            <li routerLinkActive="active">
                 <a href="javascript:;" routerLink="/users"> Users </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/payments"> Payments </a>
           </li>
           <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/admins"> Admins  </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/advertisement"> Advertisement </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/categories"> Categories  </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/pricing"> Pricing </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/redeem-points"> Redeem Points </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/deals"> Deals </a>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/subscription-plan"> Subscription Plan </a>
            </li>
            <li routerLinkActive="active">
               <a href="javascript:;"  routerLink="/technical-support"> Technical Support </a>
            </li>

            <li routerLinkActive="active">
                <a href="javascript:;" routerLink="/ratings"> Ratings </a>
            </li>
           
        </ul>
    </div>
</div> 
 -->