<div class="NotFoundWrapper">
    <div class="container">
        <div class="row">
            <div class=" offset-sm-1 col-sm-10">
                <div class="pageNotFound">
                    <h1 class="not-found-title">Page Not Found</h1>
                    <p class="not-found-text">Sorry, but the page you are looking for has not been found. Try checking the URL for error, then hit the refresh button on your browser or click below button to navigate to home page.</p>
                    <a class="link common-btn" routerLink="/">Home</a>
                </div>
            </div>
        </div>
    </div>
</div>